import { graphql, PageProps } from "gatsby";

import { useMemo } from "react";
import MainLayout from "@/components/layout/Main";
import { useStoryblok } from "@/base/lib/storyblok";
import ScientificAuthorHero from "../sections/ScientificAuthorHero";
import ScientificOtherAuthors from "../sections/ScientificOtherAuthors";

type PageData = {
  header: StoryblokStory;
  footer: StoryblokStory;
  story: StoryblokStory;
  countries: {
    nodes: Array<DataSourceEntry<CountryType>>;
  };
  authors: {
    nodes: Array<StoryblokStory>;
  };
};

function ScientificAuthorPage({ data, location }: PageProps<PageData>) {
  const { story, footer, header, countries, authors } = data;
  const parsedStory = useStoryblok<ScientificAuthorPageTemplate>(
    story,
    location
  );

  const {
    content: {
      seo,
      sa_description,
      sa_name,
      sa_position,
      sa_country,
      sa_testimonial,
      sa_author_image,
      sa_rg_link,
    },
    slug,
  } = parsedStory;

  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
  const parsedFooter = JSON.parse(footer.content) as FooterTemplate;

  const countryData = countries.nodes.find((item) => item.value === sa_country);

  const initialData = useMemo(() => {
    const scientificBoardList = authors
      ? authors.nodes
          .map(({ content, full_slug, slug }) => {
            const parsedContent = JSON.parse(
              content
            ) as ScientificAuthorPageTemplate;
            return {
              key: parsedContent._uid,
              title: parsedContent.sa_name,
              excerpt: parsedContent.sa_position,
              heroImage: parsedContent.sa_author_image,
              weight: parsedContent.sa_weight
                ? parseInt(parsedContent.sa_weight)
                : 0,
              slug,
              url: `/${full_slug}`,
            };
          })
          .filter((item) => item.slug !== slug)
          .sort((a, b) => a.weight - b.weight)
      : [];
    return { scientificBoardList };
  }, [data]);
  const authorsList = initialData.scientificBoardList;
  return (
    <MainLayout
      header={parsedHeader}
      footer={parsedFooter}
      seo={{ ...seo, og_image: seo.og_image }}
      type="website"
    >
      <ScientificAuthorHero
        sa_name={sa_name}
        sa_description={sa_description}
        sa_position={sa_position}
        countryData={countryData}
        sa_author_image={sa_author_image}
        sa_testimonial={sa_testimonial}
        sa_rg_link={sa_rg_link}
      />
      <ScientificOtherAuthors authorsList={authorsList} />
    </MainLayout>
  );
}

export const pageQuery = graphql`
  query ScientificAuthorPageQuery($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      content
      full_slug
      slug
    }
    header: storyblokEntry(field_component: { eq: "template_header" }) {
      content
    }
    footer: storyblokEntry(field_component: { eq: "template_footer" }) {
      content
    }
    authors: allStoryblokEntry(
      filter: { field_component: { eq: "template_scientificAuthor" } }
    ) {
      nodes {
        content
        slug
        full_slug
      }
    }
    countries: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "countries" } }
    ) {
      nodes {
        name
        value
      }
    }
  }
`;

export default ScientificAuthorPage;
